<template>
  <PageContainer no-loader>
    <PageTitle>
	    <div class="flex flex-row items-center gap-1" v-if="(currentCategory) && !$route.query.search">
        <span v-if="!postData && currentCategory" :class="{'text-grayed': postData}">
          {{ trans('POST_CATEGORY:' + $route.params.postCategory) }}
        </span>
		    <span v-else-if="currentCategory.onePost">
			    {{ trans('POST_CATEGORY:' + $route.params.postCategory) }}
		    </span>
		    <router-link v-else :to="{name: 'posts', params: {postCategory: currentCategory.slug}, query: {page: lastPage}}" class="hover:opacity-70 hover:text-redAccentLight font-bold text-left m-0 py-0 cursor-pointer">
			    {{ trans('POST_CATEGORY:' + $route.params.postCategory) }}
		    </router-link>
	    </div>
	    <div v-else class="flex flex-row items-center gap-1">
		    <router-link :to="{name: 'posts', query: {page: lastPage, search: searchQuery}}" class="hover:opacity-70 hover:text-redAccentLight font-bold text-left m-0 py-0 cursor-pointer">
			    {{ trans('LABEL:PostSearchResult') }}: {{ $route.query.search }}
		    </router-link>
	    </div>
    </PageTitle>
    <div class="w-full flex flex-col lg:flex-row items-start">
      <div v-if="!isMobile" class="w-full lg:w-sidebar lg:pr-6">
        <Loader v-if="postCategoriesDataLoading" class="h-sidebar"/>
        <t-card v-else class="w-full">
          <PostsNavigation @only-post-clicked="showModalOnlyPost" />
        </t-card>
      </div>
      <div class="w-full lg:w-main-container">
        <router-view/>
      </div>
    </div>
  </PageContainer>
</template>

<script>
import Loader from "@/views/Loader";
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import PostsNavigation from "@/views/content/posts/components/PostsNavigation";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import {excerpt} from "@/common/helpers/utils";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "ReadingRoom",
  components: {
    Loader,
    PageContainer,
    PageTitle,
    PostsNavigation,
  },
  computed: {
    ...mapGetters('posts', ['postData', 'currentCategory', 'lastPage', 'searchQuery']),
    ...mapGetters('dictionaries', ['postCategoriesData', 'postCategoriesDataLoading']),
  },
  data() {
    return {
      isMobile: checkIsMobileDevice(),
      excerpt: excerpt,
    }
  },
  methods: {
		...mapMutations('posts', ['setCurrentOnlyPostSlug']),
	  showModalOnlyPost(slug) {
			this.setCurrentOnlyPostSlug(slug);
	  }
  },
  mounted() {
		if(!this.$route.params.postCategory) {

			this.$router.push({name: 'post', params: {postCategory: 'news'}})
		}
  },
	beforeDestroy() {

	}

}
</script>
